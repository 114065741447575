export const agendaShows = [
    {
        dia: '00',
        mes: 'Coming soon',
        local: '',
        hora: ''
    },
    // {
    //     dia: '21',
    //     mes: 'June',
    //     local: 'Fête de la musique',
    //     hora: '(A confirmar)'
    // },
    {
        dia: '',
        mes: '',
        local: '',
        hora: ''
    }
]
